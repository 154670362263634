import React, { useState } from "react"
import Layout from "../../../components/layouts"
import Banner from "../../../components/features/Banner"
import SEO from "../../../components/common/SEO"
import ReactWOW from "react-wow"
import FeatureCards from "../../../components/features/FeatureCards"

export const query = graphql`
  query Suggestions {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`

export default ({ data }) => {
  const [toggler, setToggler] = useState(false)
  const lightboxProps = {
    toggler: toggler,
    setToggler: setToggler,
    sources: ["https://www.youtube.com/watch?v=xE2yXvWB038"],
  }

  const bannerProps = {
    title: "Suggestions",
    subtitle: `Good ideas don’t always come from the top, and making suggestions is not as easy as you would think! You could be missing out on some game changing ideas. Encouraging suggestions from workers can increase sales, reduce costs, eliminate waste, improve productivity, boost morale and even save lives.`,
    featureName: "safetymeetings",
    rightButtonText: "Why Certificate Tracking Software?",
    rightButtonSrc: "https://www.youtube.com/watch?v=xE2yXvWB038",
    mockupImg: '/features/suggestions/Suggestions-banner.png'
  }

  const seo = {
    title: "Workhub | Free Online Employee Suggestion Box Software Digital Secure",
    description: "No more cardboard boxes! Encourage ideas & promote feedback, employee engagement & stronger workplace culture by providing an anonymous, online suggestion box.",
    image: "/features/suggestions/social-card.jpg",
    // pathname,
    // article
  }

  const featureIndex = [
    {
      title: "Engagement",
      para: "Drive up engagement in your organization by allowing employees to submit suggestions regarding workplace culture, policies/procedures, and more.",
    },
    {
      title: "Rate and Comment",
      para: "Get additional feedback by allowing other workers to rate a fellow employee’s suggestion.  ",
    },
    {
      title: "Optional Anonymity",
      para: "Give workers the ability to provide insight without fear of backlash, or criticism. Select whether workers can remain anonymous when suggesting, rating, or commenting. ",
    },
    {
      title: "Approval",
      para: "Select whether admins need to review suggestions and comments before making them visible to other workers. Admins can respond directly to suggestions. ",
    },
    {
      title: "Assess Proposition Value",
      para: "If workers enter an estimated value of benefits and costs saved the software will automatically calculate a return on investment. ",
    },
    {
      title: "Transparency",
      para: "Workers can see which suggestions have been implemented and approved, and which are pending or declined.",
    },
  ]


  return (
    <Layout {...lightboxProps}>
      <SEO {...seo} {...data.site} />
      <Banner {...bannerProps} {...lightboxProps} />



      <main className="feature-main">


        <section className="feature-section video-section">
          <div>
            <div className="row video-row switch-children">

              <ReactWOW animation="fadeInLeft" delay="0.9s">
                <div className="video-bg-size" style={{ width: '45%' }}>

                  <div className="iframe-contain">
                    <iframe width="700" height="500" src="https://www.youtube.com/embed/DvVwfatjueQ" frameborder="0" allowfullscreen></iframe>
                  </div>

                </div>

              </ReactWOW>

              <div className="feature-text video-text" style={{ width: '35%' }}>
                <h2>
                  Why we love Suggestions.
                </h2>

                <p>
                  Here at Workhub, we address all our suggestions during a monthly meeting. In our experience this shows our employees that the company is listening, encourages them to feel confident in sharing and helps garner engagement in company culture. It has also allowed us to increase transparency between management and employees regarding company decisions. Every month we go over a variety of suggestions ranging in excellence, from: ‘we need tastier beers in the cooler’ to ‘the sun is in my eyes from 2-3pm on Wednesdays – can we turn it off?’ We don’t know where we would be without these recommendations from our brilliant staff.
                </p>

              </div>
            </div>
          </div>
        </section>


        <section className="feature-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 feature-text">
                <h2>Prepare yourself for a spectrum of suggestions.</h2>

                <p>
                  You have a lot of control over what workers can post. You decide whether
                  suggestions can be created anonymously, whether they need to be
                  approved before they are shared with the organization, if staff can rate or
                  comment anonymously and how many comments can be left per
                  suggestion. New suggestions will show up in the admin portal, and Admins
                  can mark them as pending, approved, implemented or declined. Not every
                  suggestion will work for your company, but you may want to let them down
                  gently with a detailed explanation. The last thing you want to do is discourage
                  future suggestions!
                </p>
              </div>

              <div className="col-lg-6 feature-img">
                <ReactWOW animation="fadeInRight" delay="0.9s">
                  <img
                    className="feature-img-left"
                    src="/features/suggestions/Suggestions-admin.png"
                    alt="Suggestions Admin View"
                  />
                </ReactWOW>
              </div>
            </div>
          </div>
        </section>

        <section className="feature-section">
          <div className="container">
            <div className="row switch-children">
              <div className="col-lg-6 feature-img">
                <ReactWOW animation="fadeInLeft" delay="0.9s">
                  <img
                    className="feature-img-right"
                    src="/features/suggestions/Suggestions-worker.png"
                    alt="Suggestions Mobile View"
                  />
                </ReactWOW>
              </div>

              <div className="col-lg-6 feature-text">
                <h2>
                  Betty has an idea.
                </h2>

                <p>
                  To make a suggestion your worker provides the background for the situation
                  at hand and the proposed solution. The worker can then select from a list of
                  potential benefits and can even calculate the expected return on
                  investment by estimating costs saved. Once submitted, other workers can
                  rate the suggestion from 1-5, add comments and give a thumbs up or down
                  to other people’s ideas (based on settings, of course). They can also see
                  which suggestions are implemented or approved, and which are pending
                  and declined. So, what was Betty’s great idea you ask? ‘Better tasting
                  dogfood....’ Interesting, well we have no idea how she discovered the taste
                  wasn’t up to par - but here’s wishing your company many impactful
                  suggestions and we hope that you have someone who is this invested in the
                  culture and happiness of your furry office friends.
                </p>

              </div>
            </div>
          </div>
        </section>

        <section className="feature-overview-container">
          <h2>Overview</h2>

          <div className="feature-overview-grid">
            {featureIndex.map(item => (
              <FeatureCards title={item.title} para={item.para} />
            ))}
          </div>
        </section>

      </main>
    </Layout>
  )
}
